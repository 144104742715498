<template>
  <div class="view">
    <v-breadcrumbs>
      <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
        >Home</v-breadcrumbs-item
      >
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item
        :exact="true"
        :to="{ name: 'processWorksheet', params: {} }"
      >
        Process Worksheet
      </v-breadcrumbs-item>
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item :to="{ name: 'processWorksheetView', params: {} }">
        View Process Worksheet
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <div>
      <worksheet-card v-if="id" :worksheet="id" :showEditButtons="true" />
      <v-sheet v-else class="pa-4 text-center">Not found</v-sheet>
    </div>

    <div class="d-flex justify-space-between ma-5">
      <v-btn @click="goBack">Back</v-btn>
      <span></span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const WorksheetCard = () =>
  import("@/components/processWorksheet/WorksheetCard.vue");

export default {
  components: {
    WorksheetCard,
  },
  data() {
    return {
      id: null,
    };
  },
  computed: {
    ...mapGetters("worksheetTypeDefinition", ["worksheetTypeDefinitions"]),
  },
  mounted() {
    this.id = this.$route.params.id ? parseInt(this.$route.params.id) : null;
    this.$emit("showParent", false);
  },
  methods: {
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
